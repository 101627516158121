import { Button } from '@mui/material';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import Loading from '../Loading/Loading';
import { getS3Url } from './Post';
import { IPost } from './Post.models';

const FallbackImg = React.lazy(() => import('../Img/Img'));

const formatCaption = (post: IPost): string => {
  const dateStr = new Date(post.CreatedOn * 1000).toDateString();
  const type = post.IsVideo ? 'Video' : 'Photo';
  const caption = post.Caption ? ` Caption: ${post.Caption}.` : '';
  const location = post.Location ? ` At ${post.Location}.` : '';

  return `${type} taken at ${dateStr}.${caption}${location}`;
};

interface ImgPostProps {
  post: IPost;
  isThumbnail?: boolean;
  toggleError: (error: boolean) => void;
}

export default function Img(props: ImgPostProps) {
  const {
    post: { OriginalUrl, S3Key, ThumbnailS3Key },
    isThumbnail,
    toggleError,
  } = props;

  const srcs = isThumbnail
    ? [getS3Url(ThumbnailS3Key || ''), OriginalUrl, getS3Url(S3Key)]
    : [getS3Url(S3Key), OriginalUrl];

  return (
    <ErrorBoundary
      fallbackRender={({ resetErrorBoundary }) => (
        <Button
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          color="secondary"
          onClick={() => resetErrorBoundary()}
        >
          Tap to retry
        </Button>
      )}
      onError={() => toggleError(true)}
      onReset={() => toggleError(false)}
    >
      <React.Suspense fallback={<Loading />}>
        <FallbackImg
          className="Img"
          srcs={srcs}
          alt={formatCaption(props.post)}
          height={props.post.Height || 24}
          width={props.post.Width || 24}
        />
      </React.Suspense>
    </ErrorBoundary>
  );
}
