import './Post.css';

import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Button, Modal, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import differenceInDays from 'date-fns/differenceInDays';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import React, { useCallback, useState } from 'react';

import useWindowSize from '../hooks/useWindowSize';
import Img from './Image-Post';
import { IPost, PostTypeEnum } from './Post.models';
import Video from './Video-Post';

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    margin: theme.spacing(1, 2),
    'max-height': '50%',
    '@media (max-width: 450px)': {
      'max-height': '15vh',
    },
    'white-space': 'pre-line',
  },
  button: {
    alignSelf: 'center',
  },
  postModalList: {
    'background-color': theme.palette.background.default,
  },
}));

const calculateAspectRatioFit = (
  srcWidth: number,
  srcHeight: number,
  maxWidth: number,
  maxHeight: number,
) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
};

export const getS3Url = (key: string): string => {
  return `${process.env.REACT_APP_ASSETS_URL}/${key}`;
};

const formatDate = (ms: number): string => {
  return differenceInDays(Date.now(), ms) > 7
    ? new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(ms)
    : formatDistanceToNowStrict(ms) + ' ago';
};

const ModalButton = (props: { href: string; text: string }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.button}
      sx={{
        marginX: 2,
        marginY: 1,
      }}
      target="_blank"
      href={props.href}
      color="primary"
      variant="contained"
    >
      {props.text}
    </Button>
  );
};

export default function Post(props: { post: IPost }) {
  const { post } = props;
  const [open, toggleOpen] = useState(false);
  const [hasError, toggleError] = useState(false);
  const classes = useStyles();
  const size = useWindowSize();

  const handleOpen = useCallback(() => {
    if (!hasError) {
      toggleOpen(true);
    }
  }, [hasError]);

  const handleClose = useCallback(() => {
    toggleOpen(false);
  }, [toggleOpen]);

  const handleError = useCallback((error: boolean) => toggleError(error), []);

  const ratio = size.width > 450 ? 0.75 : 0.5;

  const { width, height } = calculateAspectRatioFit(
    post.Width || 1000,
    post.Height || 1000,
    size.width * ratio,
    size.height * ratio,
  );

  const body = (
    <div
      className="PostModalContainer"
      style={{
        top: '25%',
        margin: 'auto',
      }}
    >
      <div style={{ width, height, color: 'white' }}>
        {post.IsVideo ? (
          <Video post={post} open={true} />
        ) : (
          <Img post={post} toggleError={handleError} />
        )}
      </div>
      <div
        className={'PostModalList ' + classes.postModalList}
        style={{ height }}
      >
        <span className="Badge">
          {post.Type === PostTypeEnum.StoryVideo ||
          post.Type === PostTypeEnum.StoryImage ? (
            <VisibilityOffOutlinedIcon />
          ) : null}
        </span>
        <p className={classes.text} style={{ textAlign: 'center' }}>
          {formatDate(post.CreatedOn * 1000)}
        </p>
        {/* Some caption somehow is an object */}
        {(post.Caption?.text || post.Caption) ? (
          <p className={classes.text} style={{ overflowX: 'auto' }}>
            {post.Caption?.text ?? post.Caption}
          </p>
        ) : null}
        {post.ShortCode && (
          <ModalButton
            href={`https://instagram.com/p/${post.ShortCode}`}
            text="View Post"
          />
        )}
        <ModalButton href={getS3Url(post.S3Key)} text="View in full size" />
      </div>
    </div>
  );

  return (
    <>
      <Modal
        className="PostModal"
        open={open}
        onClose={handleClose}
        aria-labelledby="Post modal"
      >
        {body}
      </Modal>
      <div className="PostContainer" onClick={handleOpen}>
        <div className={`Content ${hasError ? 'Error' : ''}`}>
          <Img post={post} isThumbnail={true} toggleError={handleError} />
          <span className="Badge">
            {post.IsVideo ? <VideocamOutlinedIcon /> : <ImageOutlinedIcon />}
            {post.Type === PostTypeEnum.StoryVideo ||
            post.Type === PostTypeEnum.StoryImage ? (
              <VisibilityOffOutlinedIcon />
            ) : null}
          </span>
        </div>
      </div>
    </>
  );
}
