import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import App from './App/App';
import Profile from './Profile/Profile';
import ProfileTimeline from './ProfileTimeline/ProfileTimeline';
import RandomTimeline from './ProfileTimeline/RandomTimeline';

// import your route components too

const root = createRoot(document.getElementById('root')!);
root.render(
  <StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path=":userName" element={<Profile />}>
            <Route index element={<ProfileTimeline />} />
            <Route path="random" element={<RandomTimeline />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </StrictMode>,
);
