export enum PostTypeEnum {
  Image = 'GraphImage',
  Video = 'GraphVideo',
  Images = 'GraphSidecar',
  StoryVideo = 'GraphStoryVideo',
  StoryImage = 'GraphStoryImage',

  // non-graph type
  Profile = 'Profile',
}

export interface IPost {
  /** Hash Key */
  UserId: string;
  /** Range Key */
  PostId: string;
  S3Key: string;
  Type: PostTypeEnum;
  CreatedOn: number;
  ParentId: string;
  ShortCode?: string;
  OriginalUrl: string;
  IsVideo: boolean;
  Height?: number;
  Width?: number;
  // string
  Caption?: any;
  Location?: string;
  OriginalUserId?: string;
  ThumbnailS3Key?: string;
}
