import { Button } from '@mui/material';
import { Link, Outlet } from 'react-router-dom';

export default function Profile() {
  return (
    <>
      <div>
        <Button component={Link} to="" variant="text" sx={{ color: 'white' }}>
          timeline
        </Button>
        <Button
          component={Link}
          to="random"
          variant="text"
          sx={{ color: 'white' }}
        >
          random
        </Button>
      </div>
      <Outlet />
    </>
  );
}
