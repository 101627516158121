import './ProfileTimeline.css';

import { Button } from '@mui/material';
import { Fragment, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { User } from '../Avatar/Avatar.models';
import Loading from '../Loading/Loading';
import Post from '../Post/Post';
import { IPost, PostTypeEnum } from '../Post/Post.models';
import { selectedUserState } from '../Profile/Profile.atom';
import { fetcher } from '../Utils';

export default function ProfileTimeline() {
  const { ref, inView } = useInView();
  const { userName } = useParams();
  const [users] = useRecoilState(selectedUserState);
  const user = users.find((u: User) => u.Username === userName);
  const { status, data, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery<unknown[]>(
      `posts-${user?.UserId}`,
      ({ pageParam }) =>
        fetcher(
          `${process.env.REACT_APP_API_ENDPOINT}/users/${user?.UserId}${
            pageParam
              ? '?key=' + encodeURIComponent(JSON.stringify(pageParam))
              : ''
          }`,
          { credentials: 'include' },
        ),
      {
        enabled: !!user?.UserId,
        getNextPageParam: (lastPage: any) =>
          lastPage?.items?.length > 0 ? lastPage.key : null,
      },
    );
  // need to call this in onmount
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  if (status === 'idle') {
    if (user == null) {
      return <p>User {userName} not found.</p>;
    }
    return <p>Click a profile to load a timeline.</p>;
  }

  if (status === 'error') {
    return <div>failed to load</div>;
  }

  if (status === 'loading') {
    return <Loading />;
  }

  return (
    <>
      <div className="Timeline">
        {(data?.pages || []).map((page: any, i) => (
          <Fragment key={i}>
            {page.items.map((p: IPost) => {
              // remove preview for images post
              if (p.Type === PostTypeEnum.Images && p.PostId === p.ParentId) {
                return null;
              }
              return <Post post={p} key={p.PostId} />;
            })}
          </Fragment>
        ))}
      </div>
      {hasNextPage && (
        <Button
          sx={{
            marginY: 2,
          }}
          ref={ref}
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || !!isFetchingNextPage}
          color="primary"
          variant="contained"
          tabIndex={-1}
        >
          {isFetchingNextPage ? 'Loading more...' : 'Load More'}
        </Button>
      )}
      {!hasNextPage && <p>No more posts</p>}
    </>
  );
}
