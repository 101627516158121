import { Button } from '@mui/material';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { User } from '../Avatar/Avatar.models';
import Loading from '../Loading/Loading';
import Post from '../Post/Post';
import { IPost, PostTypeEnum } from '../Post/Post.models';
import { selectedUserState } from '../Profile/Profile.atom';
import { fetcher } from '../Utils';

export default function RandomTimeline() {
  const { userName } = useParams();
  const [users] = useRecoilState(selectedUserState);
  const user = users.find((u: User) => u.Username === userName);
  const { status, data, error, refetch, isFetching, isLoading } = useQuery(
    `posts-${user?.UserId}-random`,
    async () => {
      const { items } = await fetcher(
        `${process.env.REACT_APP_API_ENDPOINT}/users/${user?.UserId}?isRandom=1`,
        { credentials: 'include' },
      );
      return items;
    },
    {
      enabled: !!user?.UserId,
    },
  );

  if (status === 'idle') {
    return <p>Click a profile to load random posts.</p>;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (status === 'error') {
    return <span>Error: {error as any}</span>;
  }

  const limited = data
    .filter((p: IPost) => {
      // remove preview for images post
      return !(p.Type === PostTypeEnum.Images && p.PostId === p.ParentId);
    })
    .slice(0, 9);
  return (
    <ErrorBoundary
      fallbackRender={({ resetErrorBoundary }) => (
        <Button
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          color="secondary"
          onClick={() => resetErrorBoundary()}
        >
          Tap to retry
        </Button>
      )}
    >
      <React.Suspense
        fallback={<Loading text="Loading random from profile..." />}
      >
        <div className="Timeline">
          {limited.map((p: IPost) => (
            <Post post={p} key={p.PostId} />
          ))}
        </div>
        <Button
          sx={{
            marginY: 2,
          }}
          onClick={() => refetch()}
          color="primary"
          variant="contained"
          disabled={isFetching || isLoading}
        >
          {isFetching || isLoading ? 'Loading...' : 'Refresh'}
        </Button>
      </React.Suspense>
    </ErrorBoundary>
  );
}
