import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';

import Loading from '../Loading/Loading';
import { selectedUserState } from '../Profile/Profile.atom';
import { fetcher } from '../Utils';
import Avatar from './Avatar';
import { User } from './Avatar.models';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
}));

export default function AvatarList() {
  const { status, data, error } = useQuery('users', async () => {
    const { items } = await fetcher(
      `${process.env.REACT_APP_API_ENDPOINT}/users`,
      { credentials: 'include' },
    );
    return items;
  });
  const classes = useStyles();
  const [, setSelectedUser] = useRecoilState(selectedUserState);

  useEffect(() => {
    if (data) {
      setSelectedUser(data);
    }
  }, [data, setSelectedUser]);

  if (status === 'loading') {
    return <Loading />;
  }

  if (status === 'error') {
    return <span>Error: {error as any}</span>;
  }

  return (
    <div className={classes.root}>
      {data.map((user: User) => (
        <Avatar user={user} key={user.UserId} />
      ))}
    </div>
  );
}
