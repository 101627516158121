import { createTheme, Theme } from '@mui/material';

export const baseTheme: Theme = createTheme({
  typography: {
    body2: {
      fontSize: 18,
    },
    button: {
      fontSize: 14,
    },
    fontFamily: 'Roboto',
  },
});
