import React from 'react';

import { getS3Url } from './Post';
import { IPost } from './Post.models';

export default function Video(props: { post: IPost; open: boolean }) {
  const { post, open } = props;
  const s3Url = getS3Url(post.S3Key);

  return (
    <video className="Video" controls={open} loop autoPlay={open}>
      <source src={s3Url} type="video/mp4" />
      Sorry, your browser doesn't support embedded videos.
    </video>
  );
}
