import { Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useParams } from 'react-router-dom';

import { getS3Url } from '../Post/Post';
import { User } from './Avatar.models';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },
    // image
    '& a > :not(span)': {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    // username
    '& a > span': {
      margin: theme.spacing(1, 0),
    },
  },
  avatar: {
    borderRadius: '50%',
  },
  avatarSelected: {
    borderRadius: '50%',
    outline: 3,
    outlineColor: 'green',
    outlineStyle: 'solid',
  },
}));

export default function Avatar(props: { user: User }) {
  const classes = useStyles();
  const { userName } = useParams();
  const { user } = props;
  const selected = user.Username === userName;

  return (
    <div
      className={classes.root}
      tabIndex={0}
      role="button"
      aria-pressed={selected ? 'true' : 'false'}
    >
      <Button
        component={Link}
        to={`${user.Username}`}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          color: 'white',
        }}
      >
        <img
          alt={user.Username}
          src={getS3Url(user.ProfilePicUrlHd)}
          className={selected ? classes.avatarSelected : classes.avatar}
          width="320"
          height="320"
        />
        <span>{user.Username}</span>
      </Button>
    </div>
  );
}
