import './App.css';

import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Outlet, useParams } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import AvatarList from '../Avatar/AvatarList';
import { baseTheme } from '../theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      // ms
      staleTime: Infinity,
      refetchInterval: 60 * 5 * 1000,
    },
  },
});

export default function App() {
  const themeObj = createTheme({
    ...baseTheme,
    palette: {
      primary: {
        main: '#3f51b5',
        dark: '#002b9a',
      },
      background: {
        default: '#424242',
      },
      mode: 'dark',
    },
  });
  const { userName } = useParams();

  return (
    <ThemeProvider theme={themeObj}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <div className="App">
            <h2 style={{ margin: 0 }}>Posts</h2>
            <AvatarList />
            {userName === undefined && <p>Click a profile to see the posts.</p>}
            <Outlet />
          </div>
        </RecoilRoot>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
